import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-short-link-redirect',
  templateUrl: './short-link-redirect.component.html',
  styleUrls: ['./short-link-redirect.component.sass'],
})
export class ShortLinkRedirectComponent implements OnInit {
  unsubscriber: Subject<boolean> = new Subject<boolean>();

  token: string | null | undefined = null;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.params['token'];
    if (!this.token || this.token === 'null' || this.token === 'undefined') {
      this.router.navigateByUrl('/home');
    }
    this.http
      .get(environment.citizensApi + '/short-link/' + this.token)
      .pipe(takeUntil(this.unsubscriber))
      .subscribe({
        next: (res: any) => {
          if (res?.url) {
            window.location.href = res.url;
          } else {
            this.router.navigateByUrl('/home');
          }
        },
        error: () => {
          this.router.navigateByUrl('/home');
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next(true);
    this.unsubscriber.unsubscribe();
  }
}
